import { PortalServico } from '@/utils/';

const BASE_PATH = '/wsportal/comumweb/'

export default class ComumwebServico extends PortalServico {

    async listarComumweb(path){
        let url = `${BASE_PATH}/${path}`;
        if(url.endsWith('/')){
            url = url.slice(0, -1); //Retira o último char;
        }
        return await this.get(url);
    }
}