<template>
  <section>
    <uspCartao
      titulo="Listagem de Arquivos"
      :fechavel="false"
    >
      <template #corpo>
        <uspSkeleton v-if="!carregado">
          <div 
            v-for="index in 10" 
            :key="index"
          >
            <div class="w-40" />
            <div class="w-30" />
            <div class="w-15" />
            <div class="w-15" />
          </div>
        </uspSkeleton>

        <uspTabela
          v-if="lista.length > 0 && carregado"
          :campos="campos"
          :clicavel="true"
          :dados="lista"
          :paginacao="false"
          class="table-responsive"
          @cliqueDuplo="cliqueDuplo"
        >
          <template #nome="{ data }">
            <div>
              <em
                :class="data.icone"
              />
                &emsp;{{ data.nome }}
            </div>
          </template>
        </uspTabela>
        <p 
          v-else-if="lista.length == 0 && carregado"
          class="alert alert-danger"
        >
          Não foi possível encontrar o arquivo ou pasta desejado
        </p>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import ComumwebServico from "../dominio/ComumwebServico.js";
import {BASE_NAME,COMUMWEB_PATH} from '@/pedroweb/router/rotas.js';

const api = ComumwebServico.build({});

export default {
  name: "ComumwebListagem",

  data() {
    return {
      campos: [
        {
          campo: 'nome',
          nome: 'Nome',
          slot: 'nome',
          ordenavel: true
        },
        {
          campo: 'tamanho',
          nome: 'Tamanho',
          ordenavel: false
        }
      ],
      carregado: false,
      comuwebUrl: process.env.VUE_APP_COMUMWEB_URL,
      lista: []
    }
  },

  watch: {
    $route() {
      this.breadcrumb(this.$route.path);
      this.listar(this.$route.path);
    }
  },

  mounted() {
    this.breadcrumb(this.$route.path);
    this.listar(this.$route.path);
  },

  methods: {
    breadcrumb(path) {
      let breadcrumb = [];
      let niveis = path.split('/');
      niveis.shift();
      let concatenado = '';
      niveis.forEach((item, idx) => {
        let nome = item;
        if(idx === 0) nome = 'STI Interno';
        else if(idx === 1) nome = 'Home Comumweb';

        concatenado = concatenado + '/' + item;
        breadcrumb.push(
          { 
            nome, 
            link: idx > 0 ? concatenado :  null
          }
        )
      });
      this.$emit("atualizar-rastreio", breadcrumb);
    },
    async cliqueDuplo({ dado }) {
      let caminho = this.$route.path;
      if(caminho.charAt(caminho.length - 1) == "/") {
        // remove o último caractere da string, no caso, a barra (/) 
        caminho = caminho.substring(0, caminho.length - 1);
      }
      if (dado.tipo != "PASTA") {
        const contexto = caminho.replace(`/${BASE_NAME}/${COMUMWEB_PATH}`, '');
        let urlArquivo = "";
        if(contexto === "") {
          urlArquivo = `${this.comuwebUrl}/${dado.nome}`;
        }
        else {
          urlArquivo = `${this.comuwebUrl}/${contexto}/${dado.nome}`;
        }
        window.open(urlArquivo, '_blank');
      }
      else {
        let novoPath = `${caminho}/${dado.nome}`;
        this.breadcrumb(novoPath);
        await this.$router.push(novoPath);
      }
    },
    async listar(caminho) {
      this.carregado = false;
      let contexto = caminho.replace(`/${BASE_NAME}/${COMUMWEB_PATH}`, '');
      api.listarComumweb(contexto).then((response) => {
        response.forEach((item) => {
          switch(item.tipo) {
            case "PASTA":
              item.icone = 'fa fa-folder-open';
              break;
            case "ARQUIVO":
              item.icone = 'far fa-file';
              break;
            case "IMAGEM":
              item.icone = 'far fa-image';
              break;
            default:
              item.icone = 'fa fa-question-circle'
          }
        })
        this.lista = response;
        }).catch((erro) => {
          this.lista = [];
          console.log(erro);
        }).finally(() => {
          this.carregado = true;
      })
    }
  }
}
</script>