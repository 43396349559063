import { PortalServico } from '@/utils/';

export default class MonitoramentoServico extends PortalServico {
  static build (options={}) {
    options.baseURL = '/pedroweb/monitoramento'
    return new this(options);
  }
  async listarSistemas() {
    return await this.get()
  }
}