<template>
  <section>
    <uspCartao
      titulo=""
      :fechavel="false"
      class="card-monitoramento"
      :class="{'tela-cheia' : telaCheia }"
    >
      <template #cabecalho>
        <section class="row d-flex align-items-center">
          <div class="col-md-5 col-12">
            Monitoramento de Sistemas Corporativos
          </div>
          <div class="col-md-7 col-12 acoes">
            <uspBotao
              icone="fas fa-sync"
              texto="Recarregar"
              contorno
              texto-carregando="Carregando..."
              :carregando="carregando"
              @clicar="recarregar"
            />
            <small>
              <uspDropdown
                class="ml-1 mr-4"
                :opcoes="opcoes"
                :texto="tituloSelecionado"
                @clicar="setarIntervalo"
              />
            </small>
            <uspBotao
              :icone="iconeExpande"
              texto="Tela cheia"
              contorno
              @clicar="expandirTela()"
            />
          </div>
        </section>
      </template>
      <template #corpo>
        <div class="px-2 row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div
            v-for="sistema in sistemas"
            :key="sistema.ctxsis"
            class="col"
          >
            <CardSistema
              :sistema="sistema"
              :carregando="carregando"
              class="fill"
            />
          </div>
        </div>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import MonitoramentoServico from "../dominio/MonitoramentoServico.js"
import CardSistema from "../components/CardSistema.vue"

const api = MonitoramentoServico.build({});

  export default {
    name: 'Monitoramento',
    components: {
      CardSistema
    },
    data() {
      return {
        carregando: false,
        telaCheia: false,
        exibir: false,
        sistemas : [],
        tituloSelecionado: 'Off',
        intervalo: '',
        erro: '',
        opcoes: [
          {
            titulo: "Off",
            id: 0,
            dados: {titulo: 'Off', tempo: null},
          },
          {
            titulo: "5s",
            id: 5,
            dados: {titulo: '5s', tempo: 5000},
          },
          {
            titulo: "10s",
            id: 10,
            dados: {titulo: '10s', tempo: 10000},
          },
          {
            titulo: "30s",
            id: 30,
            dados: {titulo: '30s', tempo: 30000},
          },
        ],
      }
    },
    computed: {
      iconeExpande() {
        return this.telaCheia ? 'fas fa-compress' : 'fas fa-expand'
      }
    },
    created() {
      this.recarregar()
    },
    methods: {
      expandirTela() {
        this.telaCheia = !this.telaCheia
      },
      setarIntervalo(dados) {
        if(dados.tempo) { this.recarregar() }
        clearInterval(this.intervalo)
        this.tituloSelecionado = dados.titulo
        this.intervalo = dados.tempo ? setInterval(() => {
            this.recarregar()
          }, dados.tempo)
          : null;
      },
      recarregar() {
        this.carregando = true
        api.listarSistemas().then((resp) => {
          this.sistemas = resp
        }).catch((erro) => {
          this.erro = erro?.mensagem ?? erro;
        }).finally(() => this.carregando = false)
      }
    }
  }
</script>

<style lang="scss">
.card-monitoramento {
  .card-header h5 {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
@import "../../componentes/estilos/temas";

.col {
  padding: 0 8px;
}

.acoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tela-cheia {
  position: absolute;
  left: 0;
  z-index: 1030;
  width: 100%;
  height: 100%;
  border-radius: 0;
  overflow-y: hidden;
}
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .tela-cheia {
      top: map-get($tema, "usp-barra-superior-altura-total");
    }
  }
}

.fill {
  max-height: 100%;
}

</style>